<template>
  <b-card>
    <title-name />
    <status-select />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Catalog_statuses/elements/Title.vue'
import StatusSelect from '@/views/Admin/Defines/Catalog_statuses/elements/Status.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleName,
    StatusSelect,
  },
}
</script>
